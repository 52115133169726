@import "~react-image-gallery/styles/css/image-gallery.css";

.player-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-image: url('https://res.cloudinary.com/intrapac/image/upload/v1616720957/displayapp_background_srnw7o.png');
}

.react-player {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.app {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  color: white;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh);
}

canvas.react-pdf__Page__canvas {
  position: absolute; 
  top: 50%; 
  left: 50%;   
  transform: translateX(-50%); 
  pointer-events: none;
}

div.pageControl {
  position: absolute; 
  left: 50%;   
  transform: translateX(-50%); 
  bottom: 0;
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80px;
  color: white;
}

div.pageControl button { 
  position: relative;
  display: flex;
  width: auto;
  height: auto;
  margin: 1rem;
  padding: 0.3rem 1rem 0.3rem 1rem;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 0 auto;
  border-radius: 100px;
  background-color: #53addb;
  color: #fff;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  min-width: 100px;
}
div.buttonContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}